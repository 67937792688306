<template>
  <div>
    <div style="position: relative;padding-bottom: 50px;line-height: 100%;">
      <quill-editor
        ref="myTextEditor"
        v-model="contentValue"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
        @change="onEditorChange($event)"
        class="cfpa-quill-editor"
        :style="{ height: 500 + 'px' }"
      >
        <div id="toolbar" slot="toolbar">
					<!-- Add font size dropdown -->
					<select class="ql-header" :title="$t('i18nn_4b9484089a14fff2')">
					  <option selected>{{$t('i18nn_653d92fa8f1011d3')}}</option>
					  <option value="1">标题1</option>
					  <option value="2">标题2</option>
					  <option value="3">标题3</option>
					  <option value="4">标题4</option>
					  <option value="5">标题5</option>
					  <!-- <option value="6">标题6</option> -->
					</select>
					<!-- <select class="ql-size" :title="$t('i18nn_32badce0902c46a7')">
					  <option value="10px">10px</option>
					  <option value="12px">12px</option>
					  <option value="14px">14px</option>
					  <option value="16px" selected>16px</option>
					  <option value="18px">18px</option>
					  <option value="20px">20px</option>
					  <option value="24px">24px</option>
					  <option value="28px">28px</option>
					  <option value="32px">32px</option>
					</select> -->
					<select class="ql-size" :title="$t('i18nn_32badce0902c46a7')">
					  <option value="small"></option>
					  <!-- Note a missing, thus falsy value, is used to reset to default -->
					  <option selected></option>
					  <option value="large"></option>
					  <option value="huge"></option>
					</select>
					
          <!-- Add a bold button -->
          <button class="ql-bold" :title="$t('i18nn_ee5a0b69f9323d4a')">Bold</button>
          <button class="ql-italic" :title="$t('i18nn_d656c6a9c3cbd795')">Italic</button>
          <button class="ql-underline" :title="$t('i18nn_15881fe9a4aa9352')">underline</button>
          <button class="ql-strike" :title="$t('i18nn_412470089bec1dfd')">strike</button>
					
					<!-- Add subscript and superscript buttons -->
					<select class="ql-color" value="color" :title="$t('i18nn_dbd7312ad2825e55')"></select>
					<select class="ql-background" value="background" :title="$t('i18nn_03e7724664b15858')"></select>
					
					<button class="ql-script" value="sub" :title="$t('i18nn_aa3b760147bd92e6')"></button>
					<button class="ql-script" value="super" :title="$t('i18nn_a0ba0a35323754ae')"></button>
					
					<button class="ql-header" value="1" title="标题1"></button>
					<button class="ql-header" value="2" title="标题2"></button>
					
          <button class="ql-blockquote" :title="$t('i18nn_c812fd61ee56d868')"></button>
          <button class="ql-code-block" :title="$t('i18nn_21414c06d3e4ff2c')"></button>
					
          <!--Add list -->
          <button class="ql-list" value="ordered" :title="$t('i18nn_57a7de477b2ebe97')"></button>
          <button class="ql-list" value="bullet" :title="$t('i18nn_49132db903eec70f')"></button>
          
          <!-- <select class="ql-font" :title="$t('i18nn_d48da5af833f1c63')">
            <option value="SimSun" selected="selected"></option>
            <option value="SimHei"></option>
            <option value="Microsoft-YaHei"></option>
            <option value="KaiTi"></option>
            <option value="FangSong"></option>
            <option value="Arial"></option>
            <option value="Times-New-Roman"></option>
            <option value="sans-serif"></option>
          </select> -->
          
          <select class="ql-align" value="align" :title="$t('i18nn_2c6b89b19200de97')"></select>
          <button class="ql-link" :title="$t('i18nn_5ed646cd1d97c310')"></button>
          <!-- You can also add your own -->
          <!-- <button id="custom-button2" @click.prevent="addTable" :title="$t('i18nn_104709397ba68e7e')"><i class="el-icon-s-grid"></i></button> -->
          <!--IE 兼容性有问题-->
          <button id="custom-button" @click.prevent="fnOpenUploadImage" :title="$t('i18nn_2674282277c3714e')"><i class="el-icon-picture"></i></button>
          <!-- <button id="custom-button" @click.prevent="fnOpenUploadVideo" :title="$t('i18nn_f5817042904f4a12')"><i class="el-icon-upload"></i></button> -->
          <button class="ql-clean" :title="$t('i18nn_407a5bd47a532136')"></button>
        </div>
      </quill-editor>
      <!-- <div class="cfpa-quill-wordCount"> -->
        <!-- <el-row> -->
          <!-- <el-col :span="4"><el-button size="mini" type="text" @click="dialogFnOpenCode = true">{{$t('i18nn_27ae8de28d3c3821')}}</el-button></el-col> -->
          <!-- <el-col :span="4"><el-button size="mini" type="text" @click="dialogFnOpenCodeHtml = true">{{$t('i18nn_04e722a308964860')}}</el-button></el-col> -->
          <!-- <el-col :span="24">
            <div class="cfpa-quill-wordCount-text">{{$t('i18nn_fae4e00e66853b23')}}<span style="color: red">{{ contentLength }}</span>{{$t('i18nn_4ebc25f9925a902b')}}</div>
          </el-col>
        </el-row>
      </div> -->
    </div>

    <!-- <el-dialog :close-on-click-modal="false" :title="title" width="800px" :visible.sync="dialogFnOpenUpload" append-to-body>
      <div style="margin-bottom: 20px;">
        <p>{{$t('i18nn_daaaeb1b7b22b126')}}</p>
        <p>1.排序完成之后记得点击 确认排序。</p>
        <p>2.如何一次性添加多张图片？</p>
        <p>windows系统：</p>
        <p>方式1、ctrl键 选中多个图片</p>
        <p>方式2、ctrl键+shift键 选中多个图片</p>
        <p>方式3、鼠标拖动 选中多个图片</p>
        <p>方式4、选择图片拖拽到'+'号处</p>
      </div>
      <div style="width: 100%;min-height: 100px;max-height: 600px;overflow: auto; text-align: left;">
       
        <HyElUpLoad2
          :maxSizeMB="6"
          :fileListShow="fileImgList"
          :fileId="imgKey"
          :limitNum="100"
          :fileKey="'GoodsQuillEditor'"
          :bucket="'huyi-oss-goods'"
          :accept="'image/gif,image/jpeg,image/jpg,image/png'"
          :drag="true"
          v-on:UploadSuccessUrl="uploadBaseImgUrl"
          v-on:UploadOnRemoveUrl="removeBaseImgUrl"
          v-on:DragSave="DragSaveBaseImgUrl"
        ></HyElUpLoad2>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align: center;">
        
        <el-button type="primary" @click="addImgListToQuill">确 定</el-button>
      </span>
    </el-dialog> -->
		
		<el-dialog :title="title" width="50%" :visible.sync="dialogFnOpenUpload" append-to-body :close-on-click-modal="false">
		  <!-- <file-upload :accept="accept" :data_extra="data_extra" @fnUploadSucess="fnUploadSucess" @fnCloseDialog="dialogFnOpenUpload = false" ref="fileUpload"></file-upload> -->
		  <!-- <hyElUpLoad :watchKey="watchKey" :fileListShow="fileList" :fileId="imgKey" :fileKey="'payment'" :bucket="'huyi-oss-user'" :accept="'image/gif,image/jpeg,image/jpg,image/png'" v-on:UploadSuccessUrl="fnUploadSucess"></hyElUpLoad>  -->
		  <!-- HyUpLoadImg.vue -->
		  <div style="width: 100%;max-height: 600px;overflow: auto;">
		    <!-- <hyUpLoadImg ref="fileUpload" :imgId="imgKey" 
				:watchKey="watchKey" :imgwidth="'auto'" 
				:imgheight="'auto'" 
				:imgKey="'HyQuillEditor'" :bucket="'huyi-oss-message'" v-on:changeImgUrl="fnUploadSucess"></hyUpLoadImg> -->
				
				<hyUpLoadImg
					ref="fileUpload"
					:imgId="imgKey"
					:watchKey="watchKey"
					:imgwidth="'auto'"
					:imgheight="'auto'"
					:folder="'QuillEditor/'"
					:imgKey="'HyQuillEditor'"
					:bucket="'hytx-oss-wh'"
					:maxSizeMB="10"
					:size="'small'"
					:autoOrient="true"
					:autoResize="true"
					v-on:changeImgUrl="fnUploadSucess"
				>
					<!-- <div slot="imgDesc">
						<span style="display: inline-block; width: 300px;">
							支持png、jpg等格式，大小建议不超过10M;
							<br />
						</span>
					</div> -->
				</hyUpLoadImg>
				
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogFnOpenUpload = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
		    <!-- <el-button type="primary" @click="fnOpenUploadSubmit">确 定</el-button> -->
		  </span>
		</el-dialog>
    <!-- <el-dialog :close-on-click-modal="false" :title="$t('i18nn_fbf379655e68a5a2')" :visible.sync="dialogFnOpenCode" append-to-body top="10px" custom-class="myFullDialog3">
      <div style="width: 100%;max-height: 600px;overflow: auto;">{{ preContent }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFnOpenCode = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
      </span>
    </el-dialog> -->
    <!-- <el-dialog :close-on-click-modal="false" :title="$t('i18nn_04e722a308964860')" :visible.sync="dialogFnOpenCodeHtml" append-to-body top="10px" custom-class="myFullDialog3">
      <div class="newsMainContent">
        <div class="ql-container ql-snow"><div class="ql-editor" v-html="preContent"></div></div>
      </div>
      <span slot="footer" class="dialog-footer"><el-button @click="dialogFnOpenCodeHtml = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button></span>
    </el-dialog> -->
  </div>
</template>
<script>
// import Vue from 'vue'
// import VueQuillEditor from 'vue-quill-editor'
// Vue.use(VueQuillEditor)
// import HyElUpLoad2 from './HyElUpLoad2.vue';
//富文本编辑器样式
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

// import Quill from 'quill';
// import HyElUpLoad from './HyElUpLoad'
// import config from '@/config'
import { Quill, quillEditor } from 'vue-quill-editor'
// import { quillEditor } from 'vue-quill-editor'
//可用，但是 移动端/小程序/app 表格过长时，展示不行
// import { Quill, quillEditor } from '@/components/Common/VueQuillEditor/index.js';

// import QuillBetterTable from 'quill-better-table'
// var QuillBetterTable = require('quill-better-table');

// console.log('QuillBetterTable', QuillBetterTable);

//表格
// Quill.register('modules/better-table', QuillBetterTable, true);

// 图片可收缩
// import { ImageDrop } from 'quill-image-drop-module'
// import {ImageResize} from 'quill-image-resize-module'

// import  ImageResize  from 'quill-image-resize-module';

// Quill.register('modules/imageResize', ImageResize);
//.ie11 不兼容
// let IsIE = !!window.ActiveXObject || 'ActiveXObject' in window;

// if (!IsIE) {
//   try {
    // var { ImageDrop } = require('quill-image-drop-module');
    // var ImageResize = require('quill-image-resize-module').default;

//     // console.log('ImageDrop', ImageDrop);
//     console.log('ImageResize', ImageResize);
//     // const ImageDrop = r => require.ensure([], () => r(require('quill-image-drop-module')), 'ImageDrop');
//     // const ImageResize = r => require.ensure([], () => r(require('quill-image-resize-module')), 'ImageResize');

    // Quill.register('modules/imageDrop', ImageDrop);
    // Quill.register('modules/imageResize', ImageResize);
//   } catch (err) {
//     console.log(err);
//   }
// }

// 自定义字体大小
// let Size = Quill.import('attributors/style/size');
// Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px'];
// Quill.register(Size, true);

// 自定义字体类型
// var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif']
// var Font = Quill.import('formats/font')
// Font.whitelist = fonts // 将字体加入到白名单
// Quill.register(Font, true)

export default {
  name: 'editor',
  components: {
    quillEditor,
    // HyElUpLoad2
    // HyElUpLoad
    // FileUpload
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    editorHeight: {
      type: Number,
      default: 355
    },
    editorWordCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // fileList:[],
      imgKey: this.$store.getters.getUserInfo.id,
      watchKey: '',

      dialogFnOpenCode: false,
      dialogFnOpenCodeHtml: false,

      contentValue: '',
      preContent: '',
      dialogFnOpenUpload: false,
      accept: '',
      uploadType: 'image',
      editorOption: {
        modules: {
          toolbar: '#toolbar',

          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          },
          // imageDrop: false, //拖动图片会转化为base64位，后端不支持base64位转图片并上传，前端上传转换为html的img标签才可以
          // imageResize: {
          //   displayStyles: {
          //     backgroundColor: 'black',
          //     border: 'none',
          //     color: 'white'
          //   },
          //   modules: ['Resize', 'DisplaySize', 'Toolbar']
          // },
          // handlers: {
          //   table: function(val) {
          //     this.quill.getModule('table').insertTable(2, 3);
          //   },
          //   'table-insert-row': function() {
          //     this.quill.getModule('table').insertRowBelow();
          //   },
          //   'table-insert-column': function() {
          //     this.quill.getModule('table').insertColumnRight();
          //   },
          //   'table-delete-row': function() {
          //     this.quill.getModule('table').deleteRow();
          //   },
          //   'table-delete-column': function() {
          //     this.quill.getModule('table').deleteColumn();
          //   }
          // },
          // table: true
        },
        placeholder: '请输入内容...'
      },
      data_extra: {
        parentId: 0,
        fileName: ''
      },
      contentLength: 0,
      // wordCount: '',
      title: '添加图片',
      quillEditorHeight: 300,

      fileImgList: [],

      //ie11添加图片有问题
      // IsIE: !!window.ActiveXObject || 'ActiveXObject' in window
    };
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    }
  },
  methods: {
    /**
     * @description [onEditorBlur 失去焦点]
     * @author   zoumiao
     * @param {Object} editor 返回的quill对象
     * @return   {null}   [没有返回]
     */
    onEditorBlur(editor) {
      this.$emit('editorBlur', editor);
    },
    /**
     * @description [onEditorFocus 获取焦点]
     * @author   zoumiao
     * @param {Object} editor 返回的quill对象
     * @return   {null}   [没有返回]
     */
    onEditorFocus(editor) {
      this.$emit('editorFocus', editor);
    },
    /**
     * @description [onEditorReady 可以输入]
     * @author   zoumiao
     * @param {Object} editor 返回的quill对象
     * @return   {null}   [没有返回]
     */
    onEditorReady(editor) {
      this.$emit('editorReady', editor);
    },
    /**
     * @description [onEditorChange 输入文本改变事件]
     * @author   zoumiao
     * @param {Object} editor 返回的编辑对象{html, text, quill}
     * @return   {null}   [没有返回]
     */
    onEditorChange(editor) {
      let html = editor.html;
      this.preContent = html;
      this.$emit('editorChange', html);
      this.contentLength = editor.text.length;
    },

    /**
     * 添加表格
     *
     * */
     // addTable(){
     //  let tableModule = quillEditor.getModule('better-table')
     //  tableModule.insertTable(3, 3)
     // },
    /**
     * @description [fnOpenUploadImage 上传图片]
     * @author   zoumiao
     * @return   {null}   [没有返回]
     */
    fnOpenUploadImage() {
      this.uploadType = 'image';
      // this.accept = config.accept.image
      this.title = '添加图片';
      this.watchKey = new Date().getTime();
      this.dialogFnOpenUpload = true;
      console.log('this.watchKey', this.watchKey);
    },
    /**
     * @description [fnOpenUploadVideo 上传视频]
     * @author   zoumiao
     * @return   {null}   [没有返回]
     */
    fnOpenUploadVideo() {
      this.uploadType = 'video';
      // this.accept = config.accept.video
      this.title = '添加视频';
      this.dialogFnOpenUpload = true;
    },
    /**
     * [fnOpenUploadSubmit 提交上传文件]
     * @author   zoumiao
     * @return   {null}   [没有返回]
     */
    // async fnOpenUploadSubmit() {
    //   await this.$refs.fileUpload.$refs.upload.submit();
    // },
    //上传图片回调
    // changeFileUrl(val) {
    //   console.log('changeFileUrl', val);
    //   this.submitUpload(val, this.contractState);
    // },
    /**
     * [fnUploadSucess 上传文件成功]
     * @author   zoumiao
     * @param {Array} uploadFileUrlList [上传文件返回的url]
     * @return   {null}   [没有返回]
     */
    fnUploadSucess(uploadFileUrlList) {
      console.log('uploadFileUrlList', uploadFileUrlList);

      this.editor.focus();

      console.log('getSelection', this.editor.getSelection());
      console.log('index', this.editor.getSelection().index);
      // for (let url of uploadFileUrlList) {
      this.editor.insertEmbed(this.editor.getSelection().index, this.uploadType, uploadFileUrlList);
      this.dialogFnOpenUpload = false;

      //设置光标在最后
      console.log('selection', this.editor.selection);
      this.editor.setSelection(this.editor.selection.savedRange.index + 1); // 这个方法可以获取光标位置
      // }
    },

    //多图上传--新增
    // uploadBaseImgUrl(val) {
    //   this.fileImgList.push(val);
    //   console.log('uploadBaseImgUrl', this.fileImgList);
    // },
    //多图上传--删除
    // removeBaseImgUrl(val) {
    //   this.fileImgList.splice(this.fileImgList.findIndex(item => item === val), 1);
    //   console.log('removeBaseImgUrl', this.fileImgList);
    // },
	 //拖拽保存
	 // DragSaveBaseImgUrl(imglist){

	 //   console.log("imglist",imglist);

	 //   // this.baseInfo.commodityImage = imglist.join(',');
  //    // if(imglist[0].indexOf('blob:')>-1){

  //    // } else {
  //      this.fileImgList = imglist;
  //    // }


	 // },
    //确定添加
    // addImgListToQuill() {
    //   this.editor.focus();
    //   console.log('fileImgList', this.fileImgList);
    //   this.fileImgList.forEach(item => {
    //     this.editor.insertEmbed(this.editor.getSelection().index, this.uploadType, item);
    //     this.editor.setSelection(this.editor.selection.savedRange.index + 1); // 这个方法可以获取光标位置，光标放置后面，否则自动往前
    //   });
    //   this.dialogFnOpenUpload = false;
    // }
  },
  created() {
    this.quillEditorHeight = document.body.clientHeight - this.editorHeight;
    this.contentValue = this.value;
    this.contentLength = this.editorWordCount || 0;
  },
  mounted() {
    // let toolbar = document.querySelector('div.ql-toolbar.ql-snow')
    // if (toolbar) {
    //   let toolbarHeight = toolbar.offsetHeight
    //   this.wordCount = {
    //     'top': `${toolbarHeight}px`
    //   }
    //   return
    // }
    // this.wordCount = {
    //   'top': '42px'
    // }
  },
  watch: {
    // Watch content change
    value(newVal, oldVal) {
      if (newVal && newVal !== this.preContent) {
        this.preContent = newVal;
        this.contentValue = newVal;
      } else if (!newVal) {
        this.contentValue = '';
      }
    }
  }
};
</script>
<style scoped lang="less">
.cfpa-quill-editor {
  // line-height: 24px;

  .ql-snow {
    background-color: #ffffff;
  }
}

// .cfpa-quill-wordCount {
//   background-color: #f5f5f5;
//   position: relative;
//   top: 48px;
//   padding: 0 10px;
//   border: 1px solid #ccc;
//   border-bottom-left-radius: 3px;
//   border-bottom-right-radius: 3px;
//   line-height: 28px;
//   font-size: 12px;

//   .cfpa-quill-wordCount-text {
//     line-height: 28px;
//     text-align: right;
//     // margin-right: 10px;
//     color: #aaa;
//   }
// }
</style>
